import React from 'react';
import { Row, Col, Typography, Divider } from 'antd';
const { Paragraph, Text } = Typography;
import { ApplicationData } from '@bwb-buildings-mono/entities';
import * as UI from '@bwb-buildings-mono/ui';

const PHONE_NUMBER = '(323)876-7444';
const EMAIL = 'lili@metroart.com';
const ADDRESS_NAME = 'Metro Art West Hollywood';
const ADDRESS_1 = '910 N Martel Ave.';
const ADDRESS_2 = 'Los Angeles, CA 90046';

const tours =[
    {
        name: 'Model 1 - 2BR, 2BA - 930 - 1300 SF',
        url: 'https://my.matterport.com/show/?model=fpL7gKRphhY&play=1'
    },
    {
        name: 'Model 2 - 2BR, 2BA - 930 - 1300 SF',
        url: 'https://my.matterport.com/show/?model=bTFd9CYNF8L&play=1'
    }
];

const amentities = {
    sentence: <div style={{fontSize: '18px', lineHeight: '18px', textAlign: 'center'}}>
      <Paragraph><Text mark strong>Great location</Text> close to restaurants, shops & more!</Paragraph>
      <Paragraph>Included parking in a secure garage.</Paragraph>
      <Paragraph>Intercom entry and secure access.</Paragraph>
      <Paragraph>Professional <Text mark strong>&nbsp;on-site manager&nbsp;</Text>.</Paragraph>
      <Paragraph>Fully equipped <Text mark strong>fitness center</Text> with quality equipment.</Paragraph>
      <Paragraph><Text mark strong>Electrical Vehicle Chargers</Text> Available</Paragraph>
      <Paragraph>Cat friendly, no dogs.</Paragraph>
    </div>,
    images: [
      {
        src: 'assets/web/common-1.jpg',
        caption: '',
        thumb: 'assets/web/common-1.jpg'
      },
      {
        src: 'assets/web/common-2.jpg',
        caption: '',
        thumb: 'assets/web/common-2.jpg'
      },
      {
        src: 'assets/web/common-3.jpg',
        caption: '',
        thumb: 'assets/web/common-3.jpg'
      },
      {
        src: 'assets/web/common-4.jpg',
        caption: '',
        thumb: 'assets/web/common-4.jpg'
      },
      {
        src: 'assets/web/common-5.jpg',
        caption: '',
        thumb: 'assets/web/common-5.jpg'
      },
      {
        src: 'assets/web/common-6.jpg',
        caption: '',
        thumb: 'assets/web/common-6.jpg'
      }
    ]
}

const features = {
    sentence: <div style={{fontSize: '18px', lineHeight: '18px', textAlign: 'center'}}>
        <Paragraph>Spacious layouts with ample space</Paragraph>
        <Paragraph>Bright, open floor plans</Paragraph>
        <Paragraph><Text mark strong>Washer and dryer</Text> in every unit.</Paragraph>
        <Paragraph><Text mark strong>Upgraded stainless steel appliances</Text>.</Paragraph>
        <Paragraph>Bright laminate flooring and new carpetted bedrooms.</Paragraph>
        <Paragraph><Text mark strong>Large balconies or patios</Text>.</Paragraph>
        <Paragraph>Spacious <Text mark strong>walk-in closets</Text></Paragraph>
        <Paragraph>Granite kitchen counters.</Paragraph>
    </div>,
    sentenceMore: <div style={{fontSize: '18px', lineHeight: '18px', textAlign: 'center'}}>
        <Row gutter={24}>
            <Col sm={24} md={12}>
                <Divider>Our Apartments</Divider>
                <Paragraph>All of the above and...</Paragraph>
                <Paragraph>New carpet in bedroom areas</Paragraph>
                <Paragraph>Oversized bathrooms with travertine counters</Paragraph>
                <Paragraph>Powerful <Text mark strong>central heat and air conditioning</Text></Paragraph>
                <Paragraph>Glass shower enclosures</Paragraph>
            </Col>
            <Col sm={24} md={12}>
                <Divider>State-of-the-art Technology</Divider>
                <Paragraph><Text mark strong>Dual pane windows</Text> with efficient low energy glazing</Paragraph>
                <Paragraph>Separate water system and <Text mark strong>individual water heaters</Text> in each unit</Paragraph>
                <Paragraph>Controlled access entry with RFID and intercom</Paragraph>
                <Paragraph>Cameras at all entry and exits to building for added security</Paragraph>
                <Divider>Metro Art Quality</Divider>
                <Paragraph>Secure, easy-access parking located beneath the building</Paragraph>
                <Paragraph>Large outdoor landscaped courtyard with fountain</Paragraph>
            </Col>
        </Row>
    </div>,
  images: [
    {
        src: 'assets/web/apt-2.jpg',
        caption: '',
        thumb: 'assets/web/apt-2.jpg'
    },
    {
        src: 'assets/web/apt-3.jpg',
        caption: '',
        thumb: 'assets/web/apt-3.jpg'
    },
    {
        src: 'assets/web/apt-4.jpg',
        caption: '',
        thumb: 'assets/web/apt-4.jpg'
    },
    {
        src: 'assets/web/apt-1.jpg',
        caption: '',
        thumb: 'assets/web/apt-1.jpg'
    },
    {
        src: 'assets/web/apt-5.jpg',
        caption: '',
        thumb: 'assets/web/apt-5.jpg'
    },
    {
        src: 'assets/web/apt-6.jpg',
        caption: '',
        thumb: 'assets/web/apt-6.jpg'
    },
    {
        src: 'assets/web/apt-7.jpg',
        caption: '',
        thumb: 'assets/web/apt-7.jpg'
    },
    {
        src: 'assets/web/studio-1.jpg',
        caption: '',
        thumb: 'assets/web/studio-1.jpg'
    },
    {
        src: 'assets/web/studio-2.jpg',
        caption: '',
        thumb: 'assets/web/studio-2.jpg'
    },
    {
        src: 'assets/web/studio-6.jpg',
        caption: '',
        thumb: 'assets/web/studio-6.jpg'
    },
    {
        src: 'assets/web/studio-7.jpg',
        caption: '',
        thumb: 'assets/web/studio-7.jpg'
    }
  ]
}

export const AppData:ApplicationData = {
    footer: <div>
        <div>
            <Row>
                <Col md={24} xs={0}>
                    { ADDRESS_NAME } — {ADDRESS_1}, { ADDRESS_2 } — <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
                </Col>
                <Col md={0} xs={24}>
                    { ADDRESS_NAME }<br/>
                    { ADDRESS_1 }<br/>
                    { ADDRESS_2 }<br/>
                    <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
                </Col>
            </Row>
        </div>
        <div style={{fontSize: '80%', marginTop: '24px'}}>
            <div>{ ADDRESS_NAME } is located in or around SHERMAN OAKS, VAN NUYS, STUDIO CITY, VALLEY GLEN, VALLEY VILLAGE, ENCINO, LAKE BALBOA, BEVERLY GLEN, BEL AIR</div>
            <div>Thank You For Looking, We Look Forward to Helping You!</div>
        </div>
    </div>,
    sections: [
        {
            element: UI.Logo,
            props: {
                logo: <img style={{height: '50px'}} src="./assets/logo.jpg"/>,
                name: ADDRESS_NAME,
                address: <div style={{lineHeight: 1}}>
                    { ADDRESS_1 }, { ADDRESS_2 } <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
                </div>
            }
        },
        {
            isNav: true,
            element: UI.Nav,
            props: {
                logo: {
                    logo: <img style={{height: '50px'}} src="./assets/logo.jpg"/>,
                    address: <div style={{lineHeight: 1}}>
                        <div style={{fontSize: '110%'}}>{ ADDRESS_NAME }</div>
                        <div style={{fontSize: '80%'}}>{ ADDRESS_1 }</div>
                        <div style={{fontSize: '80%'}}>{ ADDRESS_2 }</div>
                        <div style={{fontSize: '80%'}}>{PHONE_NUMBER}</div>
                    </div>
                },
                rightSide: (
                    <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
                ),
                rightSideModal: (
                    <div>
                        <Paragraph>
                            <UI.VirtualToursButton tours={tours}/>
                        </Paragraph>
                        <Paragraph>
                            <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
                        </Paragraph>
                    </div>
                )
            }
        },
        {
            element: UI.HeroImage,
            props: {
                image: './assets/web/hero.jpg'
            }
        },
        {
            element: UI.Tagline,
            props: {
                tagline: 'Spacious, modern apartments in West Hollywood, CA. Quiet neighborhood in a central location.',
                undertag: <div>
                    <UI.VirtualToursButton tours={tours}/>
                    <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
                </div>
            }
        },
        {
            element: UI.AboveFold,
            props: {
                images: [
                    './assets/web/top-1.jpg',
                    './assets/web/top-2.jpg',
                    './assets/web/top-3.jpg',
                    './assets/web/top-4.jpg',
                    './assets/web/top-5.jpg'
                ]
            }
        },
        {
            element: UI.SectionTextCarousel,
            label: UI.NAV_ITEM.UNITS,
            nav: 'Units',
            props: {
                title: 'About Our Apartments',
                sections: [features]
            }
        },
        {
            element: UI.SectionTextCarousel,
            label: UI.NAV_ITEM.AMENITIES,
            nav: 'Amenities',
            props: {
                title: 'Community Amenities',
                sections: [amentities]
            }
        },
        {
            element: UI.SectionImages,
            label: UI.NAV_ITEM.FLOORPLANS,
            nav: 'Floorplans',
            props: {
                title: 'Floorplans',
                images: [
                    {
                        color: '#b0984d',
                        name: '2+2 Model A',
                        info: <div>
                            <div><Text>2 Bedroom + 2 Bathroom</Text></div>
                            <div><Text strong style={{fontSize: 18}}>920 - 1,000 SF</Text></div>
                            <div><Text type="secondary"><small>Additional 60 - 130 SF of balcony space depending on unit.</small></Text></div>
                        </div>,
                        image: "./assets/floorplans/2-Bedroom.jpg",
                        bottom: <div>Square footages are appoximate. Size and layout varies from unit to unit.</div>
                    },
                    {
                        color: '#b0984d',
                        name: '2+2 Model B',
                        info: <div>
                            <div><Text>2 Bedroom + 2 Bathroom</Text></div>
                            <div><Text strong style={{fontSize: 18}}>~1,080 SF</Text></div>
                            <div><Text type="secondary"><small>Additional ~310 SF of balcony space depending on unit.</small></Text></div>
                        </div>,
                        image: "./assets/floorplans/2-Bedroom-Large.jpg",
                        bottom: <div>Square footages are appoximate. Size and layout varies from unit to unit.</div>
                    },
                    {
                        color: '#b0984d',
                        name: '2+2 Model C',
                        info: <div>
                            <div><Text>2 Bedroom + 2 Bathroom</Text></div>
                            <div><Text strong style={{fontSize: 18}}>~975 SF</Text></div>
                            <div><Text type="secondary"><small>Additional ~40 SF of balcony space depending on unit.</small></Text></div>
                        </div>,
                        image: "./assets/floorplans/Unit-111.jpg",
                        bottom: <div>Square footages are appoximate. Size and layout varies from unit to unit.</div>
                    },
                    {
                        color: '#b0984d',
                        name: '2+2 Model D',
                        info: <div>
                            <div><Text>2 Bedroom + 2 Bathroom</Text></div>
                            <div><Text strong style={{fontSize: 18}}>~900 SF</Text></div>
                            <div><Text type="secondary"><small>Additional ~40 SF of balcony space depending on unit.</small></Text></div>
                        </div>,
                        image: "./assets/floorplans/Unit-309.jpg",
                        bottom: <div>Square footages are appoximate. Size and layout varies from unit to unit.</div>
                    }
                ]
            }
        },
        {
            element: UI.SectionTitle,
            label: UI.NAV_ITEM.CONTACT,
            nav: 'Tour',
            props: {
                title: 'Tour'
            }
        },
        {
            element: UI.SectionSideBySide,
            props: {
                leftSide: <div>
                    <h3>Schedule a tour with our on-site manager:</h3>
                    <div style={{marginTop: 24}}>
                        <h1 style={{}}><a href={`tel:${PHONE_NUMBER}`}>{ PHONE_NUMBER }</a></h1>
                        <div style={{fontSize: '80%'}}>Call anytime!</div>
                    </div>
                    <div style={{marginTop: 24}}>
                        <h1 style={{}}><a href={`mailto:${EMAIL}`}>{ EMAIL }</a></h1>
                        <div style={{fontSize: '80%'}}>Email us what you are looking for and some times that work for you.</div>
                    </div>
                    <div style={{marginTop: 24}}>
                        <Paragraph>
                            <UI.VirtualToursButton tours={tours}/>
                        </Paragraph>
                        <Paragraph>
                            <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
                        </Paragraph>
                    </div>
                </div>,
                rightSide: (
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14732.989496334054!2d-118.35645430680415!3d34.08939897214247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2bed6a2f94c89%3A0xbf34b8a6130db9b5!2s910%20N%20Martel%20Ave%2C%20Los%20Angeles%2C%20CA%2090046!5e0!3m2!1sen!2sus!4v1597447910581!5m2!1sen!2sus" width="400" height="400" frameBorder={0} style={{border:"1px solid #CCC"}}></iframe>
                )
            }
        }
    ]
}